$(() => {
  function setupDropdownEventHandlers() {
    // Dropdown toggleのクリックイベント
    $(document).off('click', '.c-dropdown__toggle');
    $(document).on('click', '.c-dropdown__toggle', function (e) {
      const $dropdownMenu = $(this).parent().find('.c-dropdown__menu');
      if ($dropdownMenu.is(':visible')) {
        $dropdownMenu.hide();
      } else {
        $dropdownMenu.show();
      }
      e.stopPropagation();
    });

    // Dropdown menuを閉じる処理
    // note: dropdownがclickされたときもhideされちゃうので、dropdownがclickされたときは伝搬を止める
    $(document).on('click', '.c-dropdown', (e) => { e.stopPropagation(); });
    $(window).on('click', (e) => {
      if (!$(e.target).closest('.c-dropdown__toggle').length) {
        $('.c-dropdown__menu').hide();
      }
    });
  }

  setupDropdownEventHandlers();

  $(document).on('shown.bs.modal hidden.bs.modal', () => {
    setupDropdownEventHandlers();
  });
});
